<template>
  <div
    :class="[
      'page-header',
      $activeLang,
      'position-relative',
      backgroundPosition,
    ]"
    :style="`background-image: url('${cover}')`"
  >
    <!-- <div class="backdrop"></div> -->
    <div class="container d-flex justify-content-start align-items-center">
      <div class="page-info">
        <h2 class="sec-title">{{ title }}</h2>
        <p class="sec-desc">
          {{ desc }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["cover", "title", "desc", "backgroundPosition"],
};
</script>

<style>
</style>