<template>
  <div class="about-us-page" dir="ltr">
    <page-header :cover="require('../assets/images/about-us-cover.png')" title="Impressum"
      desc="Angaben gemäß § 5 TMG" />
    <div class="container en my-5" dir="ltr">
      <h2> Impressum </h2>
      <p class="m-0"> Angaben gemäß § 5 TMG </p>
      <p class="m-0"><a class="link-pri" href="https://g.page/digitaleka-gmbh?share"> Digitaleka GmbH<br> Steindamm
          77<br> 20099 Hamburg</a></p>
      <p class="mb-4"> Handelsregister: HRB 164341 <br> Registergericht: Amtsgericht Hamburg </p>
      <h4 class="m-0 col-title">Vertreten durch:</h4>
      <p class="mb-4"> Ahmed Ali</p>
      <h4 class="m-0 col-title">Kontakt</h4>
      <p class="mb-4"> Telefon: <a class="link-pri" href="tel:+494028407552"> +49 402 840 7552</a>
        <br /> E-Mail: <a class="link-pri" href="mailto:info@digitaleka.de">info@digitaleka.de</a>
      </p>
      <h4 class="m-0 col-title">Umsatzsteuer-ID</h4>
      <p class="mb-4"> Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE334687437 </p>
      <h4 class="m-0 col-title">EU-Streitschlichtung</h4>
      <p class="m-0"> Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
          class="link-pri" href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/.</a><br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum. Verbraucherstreitbeilegung/Universalschlichtungsstelle </p>
      <p> Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
        teilzunehmen. </p>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/misc/PageHeader.vue";
export default {
  components: {
    PageHeader,
  },

};
</script>

<style>
</style>